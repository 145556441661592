



























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PageResultDto, ListResultDto} from '@/framework/models/ResultDto';
import {
  ExercisePrescriptionDto,
  CreateOrUpdateExercisePrescriptionDto,
  GetListExercisePrescriptionDto
} from '@/framework/models/ExercisePrescription';
import ExercisePrescriptionApi from '@/api/prescription/ExercisePrescription';

import MotionFrequencyApi from '@/api/prescription/MotionFrequency'
import {MotionFrequencyDto} from '@/framework/models/MotionFrequency'

import {
  evaluationConclusionOptions,
  bmiOptions,
  whrOptions,
  bodyFatOptions,
  segmentalMuscleOptions
} from '@/utils/prescriptions'

import {Guid} from 'guid-typescript';

import PrescriptionDetails from './components/prescription-details.vue'

@Component({
  components: {
    PrescriptionDetails
  }
})
export default class ExercisePrescriptionIndex extends Vue {
  private api = new ExercisePrescriptionApi();
  private frequencyApi = new MotionFrequencyApi();
  private listQuery = new GetListExercisePrescriptionDto();
  private tableData: PageResultDto<ExercisePrescriptionDto> = {
    items: new Array<ExercisePrescriptionDto>(),
    totalCount: 0
  };

  private frequencyOptions: ListResultDto<MotionFrequencyDto> = {
    items: new Array<MotionFrequencyDto>()
  }

  private evaluationConclusionOptions = evaluationConclusionOptions
  private bmiOptions = bmiOptions
  private whrOptions = whrOptions
  private bodyFatOptions = bodyFatOptions
  private segmentalMuscleOptions = segmentalMuscleOptions

  private dialog = {
    title: '',
    visible: false
  }

  private detailsDialog = {
    id: undefined,
    visible: false
  }

  private dataForm: CreateOrUpdateExercisePrescriptionDto = {
    evaluationConclusion: undefined,
    bmi: undefined,
    whr: undefined,
    bodyFat: undefined,
    segmentalMuscle: undefined,
    frequency: undefined,
  }

  private rules = {
    evaluationConclusion: [{
      required: true,
      message: '评估结论不能为空',
      trigger: 'blur'
    }],
    bmi: [{
      required: true,
      message: 'BMI结论不能为空',
      trigger: 'blur'
    }],
    whr: [{
      required: true,
      message: 'WHR结论不能为空',
      trigger: 'blur'
    }],
    bodyFat: [{
      required: true,
      message: '体脂情况不能为空',
      trigger: 'blur'
    }],
    segmentalMuscle: [{
      required: true,
      message: '节段肌肉不能为空',
      trigger: 'blur'
    }],
    frequency: [{
      required: true,
      message: '运动频率不能为空',
      trigger: 'blur'
    }],
  }

  public handleDetails(row: any) {
    this.detailsDialog.id = row.id
    this.detailsDialog.visible = true
  }

  private async activated() {
    await this.getList();
    await this.getAllFrequencyList();
  }

  private async getList() {
    this.listQuery.sorting = ' creationTime desc '
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private async getAllFrequencyList() {
    this.frequencyOptions = await this.frequencyApi.getAllList()
  }

  private getFrequencyName(id: Guid) {
    const result = this.frequencyOptions.items.find((x: any) => {
      if (x.id === id) {
        return x;
      }
    })

    if (result) {
      return result.frequency;
    }
  }

  private getSegmentalMuscle(type: number) {
    const result = this.segmentalMuscleOptions.find((x: any) => {
      if (x.value === type) {
        return x;
      }
    })

    if (result) {
      return result.label;
    }
  }

  private handleCreate() {
    this.resetForm();
    this.dialog.title = '新增';
    this.dialog.visible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private updateHandle(row: any) {
    this.resetForm();
    this.dialog.visible = true;
    this.dialog.title = '编辑';
    this.dataForm = Object.assign({}, row);
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private resetForm() {
    this.dataForm = {
      evaluationConclusion: undefined,
      bmi: undefined,
      whr: undefined,
      bodyFat: undefined,
      segmentalMuscle: undefined,
      frequency: undefined,
    }
  }

  private handelConfirm() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }
      if (this.dialog.title === '新增') {
        await this.creating(this.dataForm);
      } else if (this.dialog.title === '编辑') {
        await this.editing(this.dataForm);
      }
    });
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList();
    this.close();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList();
    this.close();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      await this.api.DeleteAsync(row.id);
      await this.getList();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  private close() {
    this.dialog.visible = false;
    this.detailsDialog.visible = false
  }
}
