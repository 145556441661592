



























import { Component, Prop, Vue } from 'vue-property-decorator';
import NavItem from './NavItem.vue';

@Component({
  components: {
    NavItem,
  },
})
export default class NavBar extends Vue {
  @Prop({ required: true }) private pathName!: string;

  get sidebar() {
    return false;
  }

  get permission_routers() {
    return this.$store.state.permission.routers;
  }

  get sidebarWidth() {
    if (this.$store.getters.sidebar) {
      return 'auto';
    }
    return '200px';
  }

  get activeMenu() {
    const route = this.$route;
    const { meta, path } = route;
    // if set path, the sidebar will highlight the path you set
    if (meta) {
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
    }
    return path;
  }

  get routes() {
    if (this.pathName && this.pathName !== 'home') {
      const a = this.permission_routers.filter(
        (v: any) => v.path.indexOf(this.pathName) > -1
      );

      if (
        a[0] &&
        !a[0].alwaysShow &&
        a[0].children &&
        a[0].children.length > 0
      ) {
        return a[0].children;
      }
      return a;
    }
    return [];
  }
}
