

















































import { Component, Vue } from 'vue-property-decorator';
import { LoginDto } from '@/framework/models/LoginDto';
import { login } from '@/api/account';
import request from '@/utils/request';

@Component({})
export default class LoginIndex extends Vue {
  private loginForm: LoginDto = {
    password: '',
    userName: '',
  };

  private loginRules = {
    userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
    password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
  };

  private loading = false;

  private submit() {
    (this.$refs.loginForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      this.loading = true;
      const loginResult = await login(this.loginForm);
      if (loginResult.succeeded) {
        this.$store
          .dispatch('account/setToken', loginResult.access_Token)
          .then(() => {
            this.$router.push('/');
          })
          .catch(function(e) {
            window.console.error(e);
          });
      } else {
        this.$message({ message: '用户名或密码错误', type: 'error' });
      }
      this.loading = false;
    });
  }
}
