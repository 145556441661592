




















































import {Component, Vue, Prop, Watch} from 'vue-property-decorator'
import {Guid} from 'guid-typescript';
import ExercisePrescriptionApi from '@/api/prescription/ExercisePrescription';
import MuscleSiteApi from '@/api/prescription/MuscleSite';
import TrainingTypeApi from '@/api/prescription/TrainingType';
import ExercisePrescriptionItemApi from '@/api/prescription/ExercisePrescriptionItemApi'

@Component({})
export default class PrescriptionDetails extends Vue {
  @Prop({default: () => false}) private dialogVisible!: boolean
  @Prop({default: () => ''}) private id!: Guid

  private api = new ExercisePrescriptionApi();
  private siteApi = new MuscleSiteApi();
  private typeApi = new TrainingTypeApi();
  private itemApi = new ExercisePrescriptionItemApi();

  private model = {
    bmi: 0,
    bodyFat: 0,
    evaluationConclusion: 0,
    frequencyNumber: 0,
    segmentalMuscle: 0,
    whr: 0
  }

  private siteOptions = {
    items: new Array()
  }
  private typeOptions = {
    items: new Array()
  }

  private dynamicValidateForm = {
    items: new Array()
  }

  private tempId: Guid | undefined

  public removeDomain(index1: number, index2: number) {
    this.dynamicValidateForm.items[index1].actions.splice(index2, 1)
  }

  @Watch('id', {immediate: true})
  private getId(nv: any, ov: any) {
    if (nv !== ov) {
      this.tempId = nv
    }
  }

  private async mounted() {
    await this.getInfo()
    await this.getSiteList()
    await this.getTypeList()
  }

  private async getInfo() {
    if (this.tempId) {
      this.model = await this.api.getAsync(this.tempId)
      this.dynamicValidateForm = await this.itemApi.getItemInfoList(this.tempId)

      // 添加初始值
      if (this.dynamicValidateForm.items.length <= 0) {
        for (let i = 0; i < this.model.frequencyNumber; i++) {
          this.dynamicValidateForm.items.push({
            day: (i + 1),
            actions: [
              {
                siteId: undefined,
                typeId: undefined,
                frequency: 0
              }
            ]
          })
        }
      }
    }
  }

  private async getSiteList() {
    this.siteOptions = await this.siteApi.getAllList()
  }

  private async getTypeList() {
    this.typeOptions = await this.typeApi.getAllList()
  }

  private addDomain(index: number) {
    const currentLength = this.dynamicValidateForm.items[index].actions.length;
    if (this.siteOptions.items.length > currentLength) {
      this.dynamicValidateForm.items[index].actions.push({
        siteId: undefined,
        typeId: undefined,
        frequency: 0
      });
    }
  }

  private submitFrom() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }

      if (this.tempId) {
        if (this.dynamicValidateForm.items.length < this.model.frequencyNumber) {
          this.$message({message: '请按正确的天数填写', type: 'warning'});
          return false;
        }

        let err_val = 0;
        const days = new Array();
        for (const item of this.dynamicValidateForm.items) {
          const arr = item.actions.filter((v: any) => {
            return !v.siteId || !v.typeId
          })

          err_val = err_val + arr.length;
          if (arr.length > 0) {
            days.push(item.day)
          }
        }

        if (err_val > 0) {
          this.$message({message: `请选择第${days.join(',')}天训练项目`, type: 'warning'});
          return false;
        }


        await this.itemApi.saveAsync(this.tempId, this.dynamicValidateForm)
        this.$notify({message: '添加成功', title: '', type: 'success'})
        this.handleClose()
      }
    });
  }

  private handleClose() {
    this.$emit('handleClose')
  }
}
