































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {PageResultDto} from '@/framework/models/ResultDto';
import {
  FatPercentageDto,
  CreateOrUpdateFatPercentageDto,
  GetListFatPercentageDto
} from '@/framework/models/FatPercentage';
import FatPercentageApi from '@/api/prescription/FatPercentage';

@Component({})
export default class FatPercentageIndex extends Vue {
  private api = new FatPercentageApi();
  private listQuery = new GetListFatPercentageDto();
  private tableData: PageResultDto<FatPercentageDto> = {
    items: new Array<FatPercentageDto>(),
    totalCount: 0
  };
  private dialog = {
    title: '',
    visible: false
  }
  private dataForm: CreateOrUpdateFatPercentageDto = {
    ageMin: undefined,
    ageMax: undefined,
    sex: undefined,
    minFat: undefined,
    maxFat: undefined,
    conclusion: '',
    remark: '',
  }

  private rules = {
    ageMin: [{
      required: true,
      message: '年龄最小值不能为空',
      trigger: 'blur'
    }],
    ageMax: [{
      required: true,
      message: '年龄最大值不能为空',
      trigger: 'blur'
    }],
    sex: [{
      required: true,
      message: '性别不能为空',
      trigger: 'blur'
    }],
    minFat: [{
      required: true,
      message: '体脂最小值不能为空',
      trigger: 'blur'
    }],
    maxFat: [{
      required: true,
      message: '体脂最大值不能为空',
      trigger: 'blur'
    }],
    conclusion: [{
      required: true,
      message: '分析不能为空',
      trigger: 'blur'
    }],
  }

  private async activated() {
    await this.getList();
  }

  private async getList() {
    this.listQuery.sorting = ' creationTime desc '
    this.tableData = await this.api.getListAsync(this.listQuery);
  }

  private handleCreate() {
    this.resetForm();
    this.dialog.title = '新增';
    this.dialog.visible = true;
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private updateHandle(row: any) {
    this.resetForm();
    this.dialog.visible = true;
    this.dialog.title = '编辑';
    this.dataForm = Object.assign({}, row);
    this.$nextTick(() => {
      (this.$refs.dataForm as any).clearValidate();
    });
  }

  private resetForm() {
    this.dataForm = {
      ageMin: undefined,
      ageMax: undefined,
      sex: undefined,
      minFat: undefined,
      maxFat: undefined,
      conclusion: '',
      remark: '',
    }
  }

  private handelConfirm() {
    (this.$refs.dataForm as any).validate(async (valid: any) => {
      if (!valid) {
        return;
      }
      if (this.dialog.title === '新增') {
        await this.creating(this.dataForm);
      } else if (this.dialog.title === '编辑') {
        await this.editing(this.dataForm);
      }
    });
  }

  private async creating(row: any) {
    await this.api.CreateAsync(row);
    await this.getList();
    this.close();
    this.$notify({
      message: '添加成功',
      type: 'success',
      title: ''
    });
  }

  private async editing(row: any) {
    await this.api.UpdateAsync(row.id, row);
    await this.getList();
    this.close();
    this.$notify({
      message: '编辑成功',
      type: 'success',
      title: ''
    });
  }

  private handlerDelete(row: any) {
    this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'error'
    }).then(async () => {
      // TODO 代码
      await this.api.DeleteAsync(row.id);
      await this.getList();
      this.$notify({
        message: '删除成功',
        type: 'success',
        title: ''
      });
    }).catch(() => {
    });
  }

  private close() {
    this.dialog.visible = false;
  }
}
